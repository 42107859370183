import React, { useState } from "react";
import "./contact-us.css";
import TextField from "@mui/material/TextField";
import {
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Button,
} from "@mui/material";
import "react-phone-input-2/lib/style.css";
import "../../styles/muiStyles.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";

function ContactUs() {
  const [phone, setPhone] = useState(+91);

  const handleChange = (event) => {
    setPhone(event.target.value);
  };
  return (
    <section className="contact-us">
      <div id="contact-us" className="container contactus-wrap">
        <div className="contactus-left-container">
          <h2 className="commonHeading">Contact us</h2>
          <p>
            Whatsapp, cal or complete the form to learn how Wisedrive can
            protect your investment on buying pre-owned car
          </p>
          <p>Info@wisedrive.in</p>

          {/* <PhoneInput
            country={"us"}
            value={phone}
            onChange={(e) => setPhone(e)}
          /> */}
          <span
            className="contact-us-info"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <WhatsAppIcon fontSize="medium" style={{ color: "green" }} />
            <p style={{ paddingTop: "0px" }}>+91 73380 73380</p>
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <CallIcon fontSize="medium" />
            <p style={{ paddingTop: "0px" }}>+91 7353 34 35 36</p>
          </span>

          <div className="contact-us-description">
            <div
              className="contact-us-description-left"
              style={{ margin: "5px" }}
            >
              <h6>Customer Support</h6>
              <p>
                Our customer support is entirely on Whatsapp. Just send us “Hi”
                and we will take it forward from there.
              </p>
            </div>
            <div
              className="contact-us-description-right"
              style={{ margin: "5px" }}
            >
              <h6>Contact Address:</h6>
              <p>K No-661/3-1114/3,4,5, </p>
              <p style={{ paddingTop: "0px" }}>3rd Floor, No.46/4,</p>
              <p style={{ paddingTop: "0px" }}>Novel Tech Park, G B Palya,</p>
              <p style={{ paddingTop: "0px" }}>Bangalore, Karnataka - 560068</p>
            </div>
          </div>
        </div>
        <div className="contactus-right-container">
          <div className="contactus-card">
            <h2 style={{ marginBottom: "0px" }}>Get in touch</h2>
            <p style={{ paddingTop: "0px", marginBottom: "5px" }}>
              You can reach us anytime
            </p>
            <TextField
              placeholder="Your Name"
              sx={{
                width: "100%",
                marginBottom: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: 9,
                    borderColor: "#0c286a",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0c286a",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0c286a",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#0c286a",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0c286a",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#0c286a",
                  opacity: 1, // To ensure the color is applied properly
                },
              }}
            />
            <TextField
              placeholder="Your Email"
              sx={{
                width: "100%",
                marginBottom: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: 9,
                    borderColor: "#0c286a",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0c286a",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0c286a",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#0c286a",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0c286a",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#0c286a",
                  // opacity: 1, // To ensure the color is applied properly
                },
              }}
            />
            <Paper
              component="form"
              sx={{
                p: "4px 8px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                maxWidth: 600, // adjust max-width for mobile
                borderRadius: 10, // Rounded corners for a softer look
                boxShadow: 0, // Slight shadow for better visibility
                border: 1,
                borderColor: "#0c286a",
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={phone}
                onChange={handleChange}
                sx={{
                  // Styles for the Select component
                  "& .MuiSelect-select": {
                    padding: "10px 14px",
                    fontSize: "16px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0c286a", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0c286a", // Border color when focused
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#0c286a",
                    // opacity: 1, // To ensure the color is applied properly
                  },
                }}
              >
                <MenuItem value={+91}>+91</MenuItem>
                <MenuItem value={+1}>+1</MenuItem>
              </Select>

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <InputBase
                sx={{
                  flex: 1,
                  ml: 1,
                  "& .MuiInputBase-input::placeholder": {
                    color: "#0c286a",
                    // opacity: 1, // To ensure the color is applied properly
                  },
                }}
                placeholder="Phone Number"
                inputProps={{ "aria-label": "search" }}
              />
            </Paper>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              placeholder="How can we help?"
              sx={{
                width: "100%",
                marginBottom: 2,
                marginTop: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: 9,
                    borderColor: "#0c286a",
                  },
                  "&:hover fieldset": {
                    borderColor: "#0c286a",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0c286a",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#0c286a",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#0c286a",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#0c286a",
                  // opacity: 1, // To ensure the color is applied properly
                },
              }}
            />
            <Button
              sx={{
                width: "100%",
                height: "60px",
                fontSize: "24px",
                backgroundColor: "#003cc4",
                borderRadius: 3,
              }}
              variant="contained"
            >
              Send Message
            </Button>
            <p style={{ fontFamily: "gilroy", textAlign: "center" }}>
              By contacting us, you agree to our
              <span style={{ fontFamily: "gilroyBold" }}>
                {" "}
                Terms of service{" "}
              </span>{" "}
              and{" "}
              <span style={{ fontFamily: "gilroyBold" }}>Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
