import React, { useEffect, useState } from 'react';
import './PlanDetailsBuyNow.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Paper,
  TextField,
  Link,
  Chip,
  InputAdornment,
  Divider,
  Typography,
} from '@mui/material';
import {
  AcceptOnlyAlphabate,
  AcceptOnlyNumber,
} from '../../utils/commonFunctions';
// import OTPInput, { ResendOTP } from "otp-input-react";
import OtpInput from 'react-otp-input';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';
import PaymentForm from '../AffordabilityPayUWidget/AffordabilityWidget';
import { basicAuth, getInspectionPlans } from '../../Api/crmPortal';
import payuIcon from '../../assets/images/buyNowPopup/payu.png';
import EastIcon from '@mui/icons-material/East';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EmailIcon from '@mui/icons-material/Email';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { SendIpOnHomeLoad } from '../../Api/PortalApis';

const StyledTextField = styled(TextField)`
  & label {
    color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: white; // - Set the Input border
    }
    &:hover fieldset {
      border-color: white; // - Set the Input border when parent has :hover
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: white;
    }
  }
`;
const steps = [
  {
    label: 'Data collection',
    description: `Customer Name, Mobile Number and City of inspection … with a button to Verify OTP`,
    btnLabel: 'Get OTP',
  },
  {
    label: 'OTP',
    description: ' ⁠4 boxes for OTP and a butting for confirm OTP',
    btnLabel: 'Confirm OTP',
  },
  {
    label: 'Payment',
    description: `payment process (PG integration)`,
    btnLabel: 'Open Whatsapp',
  },
];

const inspectionList = [
  { isBasic: true, desc: 'Engine Health Check & Diagnostic' },
  { isBasic: true, desc: 'Transmission Assessment' },
  { isBasic: true, desc: 'Electrical System Analysis' },
  { isBasic: true, desc: 'Mechanical Components Review' },
  { isBasic: true, desc: 'Interior Evaluation (With Photos) ' },
  { isBasic: true, desc: 'Exterior Evaluation (With Photos)' },
  { isBasic: false, desc: 'OBD-2 Diagnostic Report' },
  { isBasic: false, desc: 'Engine Control Module Analysis' },
  { isBasic: false, desc: 'Body Control Module Inspection' },
  { isBasic: false, desc: 'Accident History Inspection' },
  { isBasic: false, desc: 'Repair Cost Estimate' },
  { isBasic: false, desc: 'Finance Validity & Clearance Check' },
  { isBasic: false, desc: 'Insurance Validity & Expiry Check' },
  { isBasic: false, desc: 'Traffic Challans Verification' },
  { isBasic: false, desc: 'Vehicle Crime Records Check' },
];

const PlanDetailsBuyNow = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const theme = useTheme();

  const queryParams = new URLSearchParams(location.search);
  const transactionMessage = queryParams.get('TransactionMessage');
  const transactionStatus = queryParams.get('TransactionStatus');
  const transactionID = queryParams.get('TransactionID');
  const bankReferenceNumber = queryParams.get('BankReferenceNumber');
  const inspectionCount = queryParams.get('InspectionCount');
  const amountReceived = queryParams.get('AmoutReceived');

  const planName = queryParams.get('InspectionType')
    ? queryParams.get('InspectionType')
    : 'Basic';
  console.log('planName', planName, transactionMessage);
  const [activeStep, setActiveStep] = React.useState(0);
  const [inspectionPlanDetails, setInspectionPlanDetails] = useState();
  const [selectedValue, setSelectedValue] = useState(planName);
  const [activePlanDetails, setActivePlanDetails] = useState();
  const [packageData, setPackageData] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [couponLabel, setCouponLabel] = useState();
  console.log('couponCode', couponCode);
  const maxSteps = steps.length;
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [otp, setOtp] = useState('');
  const [validateName, setValidateName] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [validateNumber, setValidateNumber] = useState(true);
  const [numberError, setNumberError] = useState(true);
  const isButtondisabled =
    name?.trim().length > 0 &&
    phone?.trim()?.length > 0 &&
    nameError &&
    numberError &&
    phone?.trim()?.length == 10;

  function parseInspectionFeatures(packages) {
    return packages?.map((item) => ({
      ...item,
      smallPackage: item?.smallPackage
        ? {
            ...item.smallPackage,
            inspectionFeatures: item?.smallPackage?.inspectionFeatures
              ? JSON.parse(item?.smallPackage?.inspectionFeatures)
              : null,
            addonFeatures: item?.smallPackage?.addonFeatures
              ? JSON.parse(item?.smallPackage?.addonFeatures)
              : null,
          }
        : null,
      highPackage: item?.highPackage
        ? {
            ...item.highPackage,
            inspectionFeatures: item?.highPackage?.inspectionFeatures
              ? JSON.parse(item?.highPackage?.inspectionFeatures)
              : null,
            addonFeatures: item?.highPackage?.addonFeatures
              ? JSON.parse(item?.highPackage?.addonFeatures)
              : null,
          }
        : null,
    }));
  }
  async function sendIpOnLoad() {
    try {
      // get ip address from the ipfy api
      const res = await axios.get('https://api.ipify.org/?format=json');

      if (res.status === 200) {
        const userIp = res.data.ip;

        const data = {
          ip: userIp,
        };
        try {
          const resp = await SendIpOnHomeLoad(data);
          if (resp.status === 200) {
          }
        } catch (error) {
          console.error('Sending ip address failed', error);
        }
      } else {
        throw new Error('Failed to get ip address from the ipfy api');
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    sendIpOnLoad();
  }, []);
  const getInspectionPlans = async () => {
    const apiUrl = `${process.env.REACT_APP_CRM_URL}/inspectionpackagepairs/activepackagesfortenantid?tenantId=6`;
    try {
      const response = await axios.get(apiUrl, basicAuth);
      const inspectionData = parseInspectionFeatures(response?.data);
      const defaultPlan = planName
        ? inspectionData?.filter(
            (item) => item.packTitle?.toLowerCase() === planName?.toLowerCase()
          )
        : inspectionData?.[0];
      setInspectionPlanDetails(inspectionData);
      setActivePlanDetails(defaultPlan?.[0]);
      if (defaultPlan?.[0]?.smallPackage) {
        setPackageData(defaultPlan?.[0]?.smallPackage);
        setTotalAmount(defaultPlan?.[0]?.smallPackage?.totalPrice);
      } else if (defaultPlan?.[0]?.highPackage) {
        setPackageData(defaultPlan?.[0]?.highPackage);
        setTotalAmount(defaultPlan?.[0]?.highPackage?.totalPrice);
      }
      console.log('defaultPlan', defaultPlan);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getInspectionPlans();
  }, []);

  useEffect(() => {
    // Extract query parameters
    const userName = queryParams.get('userName');
    const mobileNumber = queryParams.get('mobileNo');

    // Set the state with query parameter values
    setName(userName);
    setPhone(mobileNumber);
  }, [location.search]);

  const handleProceed = async () => {
    // setActiveStep(1);
    // const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/quickconnect?tenantId=6&phoneNumber=${phone}&name=${name}`;
    const baseURL = `${process.env.REACT_APP_CRM_URL}/leads/quickconnect`;
    const queryParams = {
      tenantId: 6,
      phoneNumber: phone,
      name: name,
    };

    const requestBody = {
      name: name,
      phoneNumber: phone,
    };
    const apiUrl = `${baseURL}?${new URLSearchParams(queryParams).toString()}`;
    try {
      const response = await axios.post(apiUrl, requestBody, basicAuth);
      if (response?.status == 200) {
        handleNext();
      }
      console.log('response', response);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep == 0) {
      setActiveStep(0);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRetryPayment = () => {
    const queryParams = new URLSearchParams({
      userName: name,
      mobileNo: phone,
    });
    navigate(`/plan-details-buy-now?${queryParams.toString()}`);
  };
  const handleOpenWhatsApp = () => {
    const phoneNumber = '7338073380';
    const message = 'Hi'; // Optional message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the URL in a new tab or window
    window.open(url, '_blank');
  };
  // step 1
  const handleName = (e) => {
    setNameError(false);
    const value = e.target.value;
    var isValid = AcceptOnlyAlphabate(value);
    setValidateName(isValid);
    if (isValid) {
      setNameError(isValid);
      setName(value);
    }
  };

  const handleNumber = (e) => {
    // setValidateNumber(false);
    setNumberError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    setValidateNumber(isValid);
    // console.log(isValid, "isValid");
    if (isValid) {
      setNumberError(isValid);
      setPhone(value);
    }
  };

  const handleCity = (e) => {
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setCity(value);
    }
  };
  const getOtp = async (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    if (transactionStatus) {
      reloadPageToPayNow();
    }
    setSelectedValue(event);
    let selectedPlan = inspectionPlanDetails.filter((plan) =>
      plan?.packTitle.toLowerCase().includes(event.toLowerCase())
    );
    setActivePlanDetails(selectedPlan?.[0]);
    setPackageData(
      selectedPlan?.[0]?.smallPackage
        ? selectedPlan?.[0]?.smallPackage
        : selectedPlan?.[0]?.highPackage
    );
    setTotalAmount(
      selectedPlan?.[0]?.smallPackage
        ? selectedPlan?.[0]?.smallPackage?.totalPrice
        : selectedPlan?.[0]?.highPackage?.totalPrice
    );
  };

  const applyDiscount = async (event) => {
    if (isDiscountApplied) resetCouponCode();
    else {
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/couponcode/isvalid?couponCode=${couponCode}`;
      try {
        setIsDiscountApplied(true);
        const couponResponse = await axios.get(apiUrl, basicAuth);
        let discountAmountCalc = 0;
        if (couponResponse?.data?.discountAmount) {
          discountAmountCalc =
            packageData?.costPrice - couponResponse?.data?.discountAmount;
          discountAmountCalc = Math.round(
            discountAmountCalc + (discountAmountCalc * 18) / 100
          );
          setCouponLabel('Coupon Applied Successfully');
          setDiscountAmount(couponResponse?.data?.discountAmount);
          setTotalAmount(discountAmountCalc);
        } else if (couponResponse?.data?.message) {
          setCouponLabel(couponResponse?.data?.message);
          setDiscountAmount(discountAmountCalc);
          setTotalAmount(packageData?.totalPrice);
        }
      } catch (error) {
        console.log('error', error);
      }
    }

    console.log('event', event);
  };
  const resetCouponCode = () => {
    setIsDiscountApplied(false);
    setDiscountAmount(0);
    setCouponLabel('');
    setCouponCode('');
    setTotalAmount(packageData?.totalPrice);
  };
  console.log('isDiscountApplied', isDiscountApplied);
  const handleInspectionAmount = (event) => {
    if (transactionStatus) {
      reloadPageToPayNow();
    }
    setPackageData(event);
    setTotalAmount(event?.totalPrice);
  };

  const handleViewPremium = () => {
    if (selectedValue == 'Basic') setSelectedValue('Premium');
    else setSelectedValue('Basic');
  };

  const reloadPageToPayNow = () => {
    navigate('/plan-details-buy-now');
  };
  return (
    <section style={{ paddingBottom: '20px' }}>
      <div className="container">
        <div className="plan-details-body">
          <div className="left-container">
            <div className="plan-details-buyNow-btn-container">
              {inspectionPlanDetails?.map((inspect, index) => (
                <div key={index}>
                  <Button
                    sx={{
                      fontFamily:
                        selectedValue === inspect?.packTitle
                          ? 'gilroySemiBold'
                          : 'gilroyMedium',
                      boxShadow: 'none',
                      backgroundColor:
                        selectedValue === inspect?.packTitle
                          ? '#003CC5'
                          : '#f2f2f2',
                      color:
                        selectedValue === inspect?.packTitle
                          ? 'white'
                          : 'black',
                      '&:hover': {
                        backgroundColor:
                          selectedValue === inspect?.packTitle
                            ? '#003CC5'
                            : '#f2f2f2',
                        color:
                          selectedValue === inspect?.packTitle
                            ? 'white'
                            : 'black',
                        boxShadow: 'none',
                      },
                    }}
                    fullWidth
                    variant="contained"
                    className="plan-details-btn"
                    onClick={() => handleChange(inspect?.packTitle)}
                  >
                    {inspect?.packTitle}
                  </Button>
                  <p
                    style={{
                      paddingTop: '0px',
                      textAlign: 'center',
                      fontSize: '16px',
                      color:
                        selectedValue === inspect?.packTitle
                          ? '#003CC5'
                          : 'grey',
                    }}
                  >
                    {inspect?.packCaption}
                  </p>
                </div>
              ))}
            </div>
            <div className="plan-details-buynow-cards-container">
              {activePlanDetails?.smallPackage && (
                <div
                  className={
                    packageData?.packageId ===
                    activePlanDetails?.smallPackage?.packageId
                      ? 'buy-now-card-active '
                      : 'buy-now-card'
                  }
                  onClick={() =>
                    handleInspectionAmount(activePlanDetails?.smallPackage)
                  }
                >
                  <div className="buy-now-card-header">
                    <h5>
                      {activePlanDetails?.smallPackage?.inspectionCount}{' '}
                      Inspection
                    </h5>
                  </div>
                  <Divider
                    style={{
                      marginTop: '0.5rem',
                      borderColor: '#0c286a',
                    }}
                  />
                  <p
                    style={{
                      lineHeight: '20px',
                      fontSize: '24px',
                    }}
                  >
                    Rs{' '}
                    {activePlanDetails?.smallPackage?.totalPrice /
                      activePlanDetails?.smallPackage?.inspectionCount}
                    <br />
                    <span
                      style={{
                        paddingTop: '0px',
                        fontFamily: 'gilroy',
                        fontSize: '16px',
                      }}
                    >
                      Per inspection
                    </span>
                  </p>
                </div>
              )}
              {activePlanDetails?.highPackage && (
                <div
                  className={
                    packageData?.packageId ===
                    activePlanDetails?.highPackage?.packageId
                      ? 'buy-now-card-active '
                      : 'buy-now-card'
                  }
                  onClick={() =>
                    handleInspectionAmount(activePlanDetails?.highPackage)
                  }
                >
                  {activePlanDetails?.smallPackage?.totalPrice &&
                    activePlanDetails?.highPackage?.inspectionCount &&
                    activePlanDetails?.highPackage?.totalPrice && (
                      <div className="premium">
                        Save RS{' '}
                        {activePlanDetails?.smallPackage?.totalPrice *
                          activePlanDetails?.highPackage?.inspectionCount -
                          activePlanDetails?.highPackage?.totalPrice}
                      </div>
                    )}
                  <div className="buy-now-card-header">
                    <h5>
                      {activePlanDetails?.highPackage?.inspectionCount}{' '}
                      Inspections
                    </h5>
                  </div>
                  <Divider
                    style={{
                      marginTop: '0.5rem',
                      borderColor: '#0c286a',
                    }}
                  />
                  <p
                    style={{
                      lineHeight: '20px',
                      fontSize: '24px',
                      // fontFamily: "gilroyBold",
                    }}
                  >
                    Rs{' '}
                    {Math.round(
                      activePlanDetails?.highPackage?.totalPrice /
                        activePlanDetails?.highPackage?.inspectionCount
                    )}
                    <br />
                    <span
                      style={{
                        paddingTop: '0px',
                        fontFamily: 'gilroy',
                        fontSize: '16px',
                      }}
                    >
                      per inspection
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="inspection-list-header">
              <h5>Inspection Includes</h5>
              <RouterLink
                style={{
                  textDecorationLine: 'underline',
                  fontSize: '18px',
                  fontFamily: 'gilroySemiBold',
                  color: '#FF7858',
                }}
                target="_blank"
                to={'/sample-report'}
              >
                Sample Report
              </RouterLink>
            </div>
            <div className="inspection-list-container">
              <div style={{ width: '80%' }}>
                {packageData?.inspectionFeatures?.map((inspection, index) => {
                  return (
                    <div key={index}>
                      {index < packageData?.inspectionFeatures?.length / 2 && (
                        <p style={{ paddingTop: '2px' }}>
                          <span
                            className="paynow-btn-inspection-list"
                            style={{
                              color:
                                inspection?.isvalid.toLowerCase() == 'no' &&
                                '#B2B7BE',
                            }}
                          >
                            {inspection?.isvalid.toLowerCase() == 'no' ? (
                              <CloseIcon
                                className="doneIconModal"
                                style={{
                                  color:
                                    inspection?.isvalid.toLowerCase() == 'no' &&
                                    '#B2B7BE',
                                }}
                              />
                            ) : (
                              <DoneIcon className="doneIconModal" />
                            )}
                            {inspection?.feature_name}
                            {inspection?.isvalid.toLowerCase() == 'no' && (
                              <Chip
                                sx={{
                                  fontSize: '12px',
                                  fontFamily: 'gilroySemiBold',
                                  height: '20px',
                                  backgroundColor: '#dceeff',
                                  color: '#0c286a',
                                  marginLeft: '5px',
                                }}
                                label="Premium"
                              />
                            )}
                          </span>
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div style={{ width: '80%' }}>
                {packageData?.inspectionFeatures?.map((inspection, index) => {
                  return (
                    <div key={index}>
                      {index > packageData?.inspectionFeatures?.length / 2 && (
                        <p style={{ paddingTop: '2px' }}>
                          <span
                            className="paynow-btn-inspection-list"
                            style={{
                              color:
                                inspection?.isvalid.toLowerCase() == 'no' &&
                                '#B2B7BE',
                            }}
                          >
                            {inspection?.isvalid.toLowerCase() == 'no' ? (
                              <CloseIcon
                                className="doneIconModal"
                                style={{
                                  color:
                                    inspection?.isvalid.toLowerCase() == 'no' &&
                                    '#B2B7BE',
                                }}
                              />
                            ) : (
                              <DoneIcon className="doneIconModal" />
                            )}
                            {inspection?.feature_name}
                            {inspection?.isvalid.toLowerCase() == 'no' && (
                              <Chip
                                sx={{
                                  fontSize: '12px',
                                  fontFamily: 'gilroySemiBold',
                                  height: '20px',
                                  backgroundColor: '#dceeff',
                                  color: '#0c286a',
                                  marginLeft: '5px',
                                }}
                                label="Premium"
                              />
                            )}
                          </span>
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="inspection-list-header">
              <h5>Additional Support</h5>
            </div>
            <div className="inspection-list-container">
              <div style={{ width: '95%' }}>
                <h6
                  style={{
                    fontFamily: 'gilroySemiBold',
                    fontSize: '20px',
                    paddingLeft: '20px',
                  }}
                >
                  {packageData?.addonFeatures?.features[0]?.['sub-title']}
                </h6>
                {packageData?.addonFeatures?.features[0]?.features?.map(
                  (feature, index) => (
                    <p style={{ paddingTop: '2px' }}>
                      <span
                        style={{
                          color:
                            feature?.isvalid.toLowerCase() == 'no' && '#B2B7BE',
                          fontFamily: 'gilroyMedium',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                        }}
                      >
                        {feature?.isvalid.toLowerCase() == 'no' ? (
                          <CloseIcon
                            className="doneIconModal"
                            style={{
                              color: '#B2B7BE',
                            }}
                          />
                        ) : (
                          <DoneIcon className="doneIconModal" />
                        )}
                        {feature?.name}
                        {feature?.isvalid?.toLowerCase() == 'no' &&
                          (activePlanDetails?.packTitle?.toLocaleLowerCase() ===
                          'premium' ? (
                            <Chip
                              sx={{
                                fontSize: '12px',
                                fontFamily: 'gilroySemiBold',
                                height: '20px',
                                backgroundColor: '#dceeff',
                                marginLeft: '5px',
                                color: '#0c286a',
                              }}
                              label="3 Inspections Pack"
                            />
                          ) : (
                            <Chip
                              sx={{
                                fontSize: '12px',
                                fontFamily: 'gilroySemiBold',
                                height: '20px',
                                backgroundColor: '#dceeff',
                                marginLeft: '5px',
                                color: '#0c286a',
                              }}
                              label="Premium"
                            />
                          ))}
                      </span>
                    </p>
                  )
                )}
              </div>
              <div style={{ width: '100%' }}>
                <h6
                  className="inspection-prepost-header"
                  style={{
                    fontFamily: 'gilroySemiBold',
                    fontSize: '20px',
                    paddingLeft: '20px',
                  }}
                >
                  {packageData?.addonFeatures?.features[1]?.['sub-title']}
                </h6>
                {packageData?.addonFeatures?.features[1]?.features?.map(
                  (feature, index) => (
                    <p style={{ paddingTop: '2px' }}>
                      <span
                        style={{
                          color:
                            feature?.isvalid.toLowerCase() == 'no' && '#B2B7BE',
                          fontFamily: 'gilroyMedium',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                        }}
                      >
                        {feature?.isvalid.toLowerCase() == 'no' ? (
                          <CloseIcon
                            className="doneIconModal"
                            style={{
                              color: '#B2B7BE',
                            }}
                          />
                        ) : (
                          <DoneIcon className="doneIconModal" />
                        )}
                        {feature?.name}
                        {feature?.isvalid.toLowerCase() == 'no' &&
                          (activePlanDetails?.packTitle?.toLocaleLowerCase() ===
                          'premium' ? (
                            <Chip
                              sx={{
                                fontSize: '12px',
                                fontFamily: 'gilroySemiBold',
                                height: '20px',
                                backgroundColor: '#dceeff',
                                marginLeft: '5px',
                                color: '#0c286a',
                              }}
                              label="3 Inspections Pack"
                            />
                          ) : (
                            <Chip
                              sx={{
                                fontSize: '12px',
                                fontFamily: 'gilroySemiBold',
                                height: '20px',
                                backgroundColor: '#dceeff',
                                marginLeft: '5px',
                                color: '#0c286a',
                              }}
                              label="Premium"
                            />
                          ))}
                      </span>
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="right-container">
            <div
              className={
                transactionStatus?.toLocaleLowerCase() === 'success'
                  ? 'buynow-payment-container-success'
                  : transactionStatus === 'failure'
                  ? 'buynow-payment-container-failure'
                  : 'buynow-payment-container'
              }
            >
              {transactionStatus?.toLocaleLowerCase() === 'success' ? (
                <div className="buy-now-payment-table-content">
                  <div className="transaction-success-header-div">
                    <CheckCircleIcon
                      sx={{ color: 'green', width: '50px', height: '50px' }}
                    />
                    <p>paid</p>
                    <h5>Rs {amountReceived}</h5>
                    <p>To Wisedrive Technologies Pvt Ltd</p>
                  </div>
                  <Divider
                    style={{
                      marginTop: '0.5rem',
                      marginBottom: '0.5rem',
                      borderColor: 'green',
                    }}
                  />
                  <div>
                    <p style={{ textAlign: 'center' }}>Transaction Details</p>
                    <p>Transaction No: {transactionID}</p>
                    <p>Ref No: {bankReferenceNumber}</p>
                    <p>Inspection Type: {planName}</p>
                    <p>No. of Inspections: {inspectionCount}</p>
                  </div>
                  <div className="transaction-communication">
                    <EmailIcon sx={{ color: '#ae1144' }} />
                    <p
                      style={{
                        paddingTop: '0px',
                        color: '#ae1144',
                        lineHeight: '16px',
                      }}
                    >
                      Payment Receipt has been sent to the{' '}
                      <span
                        style={{
                          fontSize: '16px',
                          fontFamily: 'gilroyBold',
                          color: '#ae1144',
                        }}
                      >
                        customer@email.com
                      </span>
                    </p>
                  </div>
                  <Divider
                    style={{
                      marginTop: '0.5rem',
                      marginBottom: '0.5rem',
                      borderColor: 'green',
                    }}
                  />
                  <p style={{ textAlign: 'center' }}>
                    Book Inspection / Contact us
                  </p>
                  <div className="transaction-communication">
                    <WhatsAppIcon
                      sx={{ color: 'green', height: '30px', width: '30px' }}
                    />
                    <p style={{ lineHeight: '16px' }}>
                      Say hi on{' '}
                      <span
                        style={{
                          fontSize: '16px',
                          fontFamily: 'gilroyBold',
                        }}
                      >
                        Whatsapp at 73380 73380{' '}
                      </span>{' '}
                      for any queries from 9am - 7pm Mon - Sat.
                    </p>
                  </div>
                  <div className="transaction-communication">
                    <SupportAgentRoundedIcon
                      sx={{ height: '30px', width: '30px' }}
                    />
                    <p style={{ lineHeight: '16px' }}>
                      <span
                        style={{
                          fontSize: '16px',
                          fontFamily: 'gilroyBold',
                        }}
                      >
                        Call us at 7353 34 35 36{' '}
                      </span>{' '}
                      to Book inspection with our agent
                    </p>
                  </div>
                </div>
              ) : transactionStatus?.toLocaleLowerCase() === 'failure' ? (
                <div className="buy-now-payment-table-content">
                  <div className="transaction-success-header-div">
                    <CancelRoundedIcon
                      sx={{ color: '#aa1945', width: '50px', height: '50px' }}
                    />
                    <p>Payment Failed</p>
                    <h5>Rs {amountReceived}</h5>
                    <p>To Wisedrive Technologies Pvt Ltd</p>
                  </div>
                  <Divider
                    style={{
                      marginTop: '0.5rem',
                      marginBottom: '0.5rem',
                      borderColor: '#aa1945',
                    }}
                  />
                  <div>
                    <p style={{ textAlign: 'center' }}>Transaction Details</p>
                    <p>Txn No:{transactionID}</p>
                  </div>

                  <Button
                    variant="outlined"
                    sx={{
                      height: '50px',
                      width: '100%',
                      backgroundColor: '#DCEEFF',
                      fontFamily: 'gilroyMedium',
                      fontSize: '18px',
                      boxShadow: 'none',
                      color: '#0C286A',
                      '&:hover': {
                        backgroundColor: '#DCEEFF',
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => handleRetryPayment()}
                  >
                    Retry Payment
                  </Button>
                  <Divider
                    style={{
                      marginTop: '1.5rem',
                      marginBottom: '0.5rem',
                      borderColor: '#aa1945',
                    }}
                  />
                  <p style={{ textAlign: 'center' }}>
                    Book Inspection / Contact us
                  </p>
                  <div className="transaction-communication">
                    <WhatsAppIcon
                      sx={{ color: 'green', height: '30px', width: '30px' }}
                    />
                    <p style={{ lineHeight: '16px' }}>
                      Say hi on{' '}
                      <span
                        style={{
                          fontSize: '16px',
                          fontFamily: 'gilroyBold',
                        }}
                      >
                        Whatsapp at 73380 73380{' '}
                      </span>{' '}
                      for any queries from 9am - 7pm Mon - Sat.
                    </p>
                  </div>
                  <div className="transaction-communication">
                    <SupportAgentRoundedIcon
                      sx={{ height: '30px', width: '30px' }}
                    />
                    <p style={{ lineHeight: '16px' }}>
                      <span
                        style={{
                          fontSize: '16px',
                          fontFamily: 'gilroyBold',
                        }}
                      >
                        Call us at 7353 34 35 36{' '}
                      </span>{' '}
                      to Book inspection with our agent
                    </p>
                  </div>
                </div>
              ) : (
                <div className="buy-now-payment-table-content">
                  <div className="div-invoice" style={{ marginTop: '0px' }}>
                    <p style={{ fontSize: '22px' }}>Summary</p>
                    <p style={{ fontFamily: 'gilroy', color: 'grey' }}>
                      {selectedValue == 'Basic' ? 'Basic Plan' : 'Premium Plan'}
                    </p>
                  </div>
                  <div className="div-invoice" style={{ marginTop: '0.5rem' }}>
                    <p>
                      Package Amount <br />
                      {`(${
                        packageData?.inspectionCount
                      } Inspection x ₹ ${Math.round(
                        packageData?.costPrice / packageData?.inspectionCount
                      )})`}
                    </p>
                    <p style={{ fontSize: '18px' }}>{packageData?.costPrice}</p>
                  </div>
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Coupon Code"
                      fullWidth
                      onChange={(event) => {
                        setCouponCode(event.target.value);
                      }}
                      value={couponCode}
                      style={{ height: '40px !important' }}
                      sx={{
                        height: '40px !important',
                        alignItems: 'baseline',
                        borderBottomColor: 'white',
                        textTransform: 'capitalize !important',
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'white',
                        },
                        '& .MuiInput-underline': {
                          borderBottomColor: 'white',
                        },
                        '&.MuiInputBase-root-MuiOutlinedInput-root': {
                          fontFamily: 'gilroy',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Link
                              onClick={() => applyDiscount(couponCode)}
                              style={{
                                // marginBottom: "20px",
                                color: '#eb6d47',
                                textDecoration: 'none',
                                fontFamily: 'gilroyBold',
                              }}
                            >
                              {isDiscountApplied ? 'Change' : 'Apply'}
                            </Link>
                          </InputAdornment>
                        ),
                      }}
                      className="txt-promoCoe"
                    />
                  </div>
                  <div>
                    {couponLabel && (
                      <p style={{ color: 'red' }}>{couponLabel}</p>
                    )}
                  </div>

                  <div className="div-invoice" style={{ paddingTop: '10px' }}>
                    <p>Discount Applied </p>
                    <p style={{ color: discountAmount > 0 && 'red' }}>
                      {discountAmount > 0 && '-'}₹ {discountAmount}
                    </p>
                  </div>
                  <div className="div-invoice">
                    <p>Tax ({packageData?.taxPercent}% GST) </p>
                    <p>
                      ₹{' '}
                      {Math.round(
                        ((packageData?.costPrice - discountAmount) *
                          packageData?.taxPercent) /
                          100
                      )}
                    </p>
                  </div>
                  <div className="div-invoice seperator desktop">
                    <>&nbsp;</>
                  </div>
                  <div className="div-invoice" style={{ paddingTop: '10px' }}>
                    <h4>Total Amount (INR)</h4>
                    <p
                      style={{ fontSize: '25px', fontFamily: 'gilroySemiBold' }}
                    >
                      ₹ {totalAmount}
                    </p>
                  </div>
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Your Name"
                      value={name}
                      required
                      sx={{
                        fontFamily: 'gilroy',
                      }}
                      error={!validateName}
                      helperText={
                        !validateName
                          ? 'Please enter only text (letters and spaces).'
                          : ''
                      }
                      className="txt-promoCoe"
                      inputProps={{ maxLength: 20 }}
                      onChange={(e) => handleName(e)}
                    />
                  </div>
                  {/* {!nameError && name?.trim().length > 0 && (
                    <div className="div-promocode">
                      <Typography variant="error" style={{ color: "red" }}>
                        Please Enter Valid Name
                      </Typography>
                    </div>
                  )} */}
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Mobile Number"
                      required
                      value={phone}
                      sx={{
                        fontFamily: 'gilroy',
                      }}
                      error={!validateNumber}
                      helperText={
                        !validateNumber ? 'Please enter Valid Mobile No' : ''
                      }
                      className="txt-promoCoe"
                      onChange={(e) => handleNumber(e)}
                    />
                  </div>
                  {/* {!numberError && phone?.trim().length > 0 && phone != "" && (
                    <div className="div-promocode">
                      <Typography variant="error" style={{ color: "red" }}>
                        Please Enter Valid Number
                      </Typography>
                    </div>
                  )} */}

                  <div
                    style={{
                      display: 'flex',
                      // marginTop: "1rem",
                      justifyContent: 'space-around',
                    }}
                  >
                    <PaymentForm
                      userData={{
                        userName: name,
                        nameError: nameError,
                        couponCode: couponCode,
                        Mobile: phone,
                        numberError: numberError,
                      }}
                      isButtondisabled={isButtondisabled}
                      packageDetails={{
                        amount: totalAmount,
                        productinfo: 'Inspection',
                        phone: phone,
                        firstName: name,
                        packageInstanceId: packageData?.packageInstanceId,
                        couponCode: couponCode,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '1.5rem',
                      justifyContent: 'space-around',
                    }}
                  >
                    <p style={{ textAlign: 'center', lineHeight: '20px' }}>
                      By Proceeding, I understand and agree with the{' '}
                      <span style={{ fontFamily: 'gilroySemiBold' }}>
                        Terms of Service
                      </span>{' '}
                      and{' '}
                      <span style={{ fontFamily: 'gilroySemiBold' }}>
                        Privacy Policy{' '}
                      </span>
                      of Wisedrive Technologies Pvt Ltd
                    </p>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: '1.5rem',
                        marginBottom: '1.5rem',
                      }}
                    >
                      <p>Payments secured by</p>
                      <img src={payuIcon} width="80px" height="40px" alt="" />
                    </div>
                  </div>
                  {/* <div className="div-invoice btn-modal-paynow">
                  <button onClick={handleProceed}>Pay Now</button>
                </div> */}
                </div>
              )}
            </div>
            {/* <div
              className="buynow-payment-container"
              style={{
                border: activeStep == 2 && "2px solid #1BA553",
              }}
            >
              {activeStep == 0 && (
                <div className="buy-now-payment-table-content">
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  >
                    Any Questions? Contact Us
                  </p>
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      label="Your Name"
                      sx={{
                        fontFamily: "gilroy",
                      }}
                      className="txt-promoCoe"
                      inputProps={{ maxLength: 20 }}
                      onChange={(e) => handleName(e)}
                    />
                  </div>
                  {!nameError && name !== "" && (
                    <div className="div-promocode">
                      <Typography variant="error" style={{ color: "red" }}>
                        Please Enter Valid Name
                      </Typography>
                    </div>
                  )}
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      label="Mobile Number"
                      sx={{
                        fontFamily: "gilroy",
                      }}
                      className="txt-promoCoe"
                      onChange={(e) => handleNumber(e)}
                    />
                  </div>
                  {!numberError && phone != "" && (
                    <div className="div-promocode">
                      <Typography variant="error" style={{ color: "red" }}>
                        Please Enter Valid Number
                      </Typography>
                    </div>
                  )}
                  <div style={{ marginTop: "5px" }}>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "50px",
                        width: "100%",
                        backgroundColor: "#DCEEFF",
                        fontFamily: "gilroyMedium",
                        fontSize: "18px",
                        boxShadow: "none",
                        color: "#0C286A",
                        "&:hover": {
                          backgroundColor: "#DCEEFF",
                          boxShadow: "none",
                        },
                      }}
                      disabled={!numberError && !nameError}
                      onClick={() => handleProceed()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}
              {/* {activeStep == 1 && (
                <div className="buy-now-payment-table-content">
                  <div className="buyNow-payment-otp">
                    <p
                      style={{
                        textAlign: "center",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      OTP Sent to your mobile No
                    </p>
                    <p
                      style={{
                        display: "flex",
                        gap: "5px",
                        fontFamily: "gilroy",
                        paddingTop: "0px",
                        justifyContent: "center",
                      }}
                    >
                      +91 9440585685
                      <Link
                        onClick={() => handleBack()}
                        style={{
                          fontFamily: "gilroyBold",
                        }}
                      >
                        Edit
                      </Link>
                    </p>
                    <div className="div-promocode">
                      <div style={{ paddingLeft: "30px" }}>
                        <OtpInput
                          // sx={}
                          className="otp-input"
                          value={otp}
                          onChange={(e) => {
                            setOtp(e);
                          }}
                          numInputs={4}
                          // renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                        <p style={{ textAlign: "center" }}>
                          <Link> Resend OTP </Link>
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          height: "50px",
                          width: "100%",
                          backgroundColor: "#DCEEFF",
                          fontFamily: "gilroyMedium",
                          fontSize: "18px",
                          boxShadow: "none",
                          color: "#0C286A",
                          "&:hover": {
                            backgroundColor: "#DCEEFF",
                            boxShadow: "none",
                          },
                        }}
                        onClick={() => handleNext()}
                      >
                        Confirm Otp
                      </Button>
                    </div>
                  </div>
                </div>
              )} *
              {activeStep == 1 && (
                <div className="buy-now-payment-table-content-whatsApp">
                  <p
                    style={{
                      fontFamily: "gilroy",
                      fontSize: "14px",
                      textAlign: "center",
                      alignItems: "center",
                      color: "#1BA553",
                    }}
                  >
                    Thank you for contacting Us. We have received Your request
                    and will contact you shortly
                  </p>
                  <WhatsAppIcon style={{ color: "#1BA553" }} />
                  <p
                    style={{
                      paddingTop: "0px",
                      fontFamily: "gilroy",
                      fontSize: "14px",
                      // textAlign: "center",
                    }}
                  >
                    Connect with us on whatsapp at{" "}
                    <span
                      style={{
                        fontFamily: "gilroybold",
                        fontSize: "20px",
                        color: "#1BA553",
                      }}
                    >
                      73380 73380
                    </span>
                    . Simply send "Hi" to receive furture information
                  </p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      sx={{
                        height: "50px",
                        width: "100%",
                        backgroundColor: "#CCEED1",
                        fontFamily: "gilroyMedium",
                        fontSize: "18px",
                        boxShadow: "none",
                        color: "#25632D",
                        "&:hover": {
                          backgroundColor: "#CCEED1",
                          boxShadow: "none",
                        },
                      }}
                      variant="outlined"
                      onClick={() => handleOpenWhatsApp()}
                    >
                      Open WhatsApp
                    </Button>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div className="sticky-contactUs">
          <div className="sticky-contactUs-container">
            <p
              style={{
                paddingTop: '3px',
                color: 'white',
                fontFamily: 'gilroy',
              }}
            >
              Contact us on{' '}
              <span style={{ fontFamily: 'gilroySemiBold' }}>
                WhatsApp at 73380 73380{' '}
              </span>
              for any queries 24/7. Happy to Assist.
            </p>
            <Button
              variant="text"
              sx={{ color: 'white' }}
              startIcon={
                <WhatsAppIcon
                  sx={{ width: '25px', height: '25px', color: 'white' }}
                />
              }
              endIcon={
                <EastIcon
                  sx={{ width: '25px', height: '25px', color: 'white' }}
                />
              }
              onClick={() => handleOpenWhatsApp()}
            >
              Open Whatsapp
            </Button>
          </div>
          <div
            onClick={() => handleOpenWhatsApp()}
            className="sticky-contactUs-container-mobile "
          >
            <p
              style={{
                paddingTop: '3px',
                color: 'white',
                fontFamily: 'gilroy',
                fontSize: '16px',
              }}
            >
              For queries, send Hi on{' '}
              <span style={{ fontFamily: 'gilroySemiBold' }}>WhatsApp</span>{' '}
            </p>
            <EastIcon sx={{ width: '25px', height: '25px', color: 'white' }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanDetailsBuyNow;
