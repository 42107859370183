import React from "react";
import "./price.css";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { convert } from "../../utils/commonFunctions";
import image from "../../assets/images/home/image5.png";
import inspectImg from "../../assets/images/price/inspectLogo.png";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import PriceRightImg from "../../assets/images/price/priceRightImg.png";

function Price({ pageData }) {
  const [isModalOpen, setIsmodalOpen] = useState(false);
  // const [isCardOpen, setIsCardOpen] = useState(false)
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#003cc4",
    },
  }));
  return (
    <section>
      <div className="container">
        <div className="price-wrap">
          <div className="price-left-container">
            <h2 style={{ marginBottom: "0px" }}>
              Numbers <br />
              that don’t lie
            </h2>
            <p>
              Trusted by 200,000+ customers for inspecting pre-owned cars before
              purchase
            </p>
            <div className="numbers-dont-lie-desc">
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3rem",
                  alignItems: "flex-end",
                }}
              >
                <h3>200,000+ </h3>
                <p style={{ textAlign: "end" }}>Used Car inspection</p>
              </span>
              <BorderLinearProgress
                variant="determinate"
                sx={{ marginTop: "5px", height: "6px" }}
                value={60}
              />
              <p
                style={{
                  textAlign: "end",
                  fontFamily: "gilroyBold",
                  paddingTop: "2px",
                }}
              >
                Customer who bought our inspections
              </p>
            </div>
            <div className="numbers-dont-lie-desc">
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3rem",
                  alignItems: "flex-end",
                }}
              >
                <h3> 75,000+ </h3>
                <p style={{ textAlign: "end" }}> Used Car Warranty</p>
              </span>
              <BorderLinearProgress
                variant="determinate"
                sx={{ marginTop: "5px", height: "6px" }}
                value={40}
              />
              <p
                style={{
                  textAlign: "end",
                  fontFamily: "gilroyBold",
                  paddingTop: "2px",
                }}
              >
                Active Warranty Customers in India
              </p>
            </div>{" "}
            <div className="numbers-dont-lie-desc">
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3rem",
                  alignItems: "flex-end",
                }}
              >
                <h3>80,00,000+ </h3>
                <p style={{ textAlign: "end" }}>Trust in Wisedrive</p>
              </span>
              <BorderLinearProgress
                variant="determinate"
                sx={{ marginTop: "5px", height: "6px" }}
                value={80}
              />
              <p
                style={{
                  textAlign: "end",
                  fontFamily: "gilroyBold",
                  paddingTop: "2px",
                }}
              >
                Km travelled by our happy customers
              </p>
            </div>{" "}
            <div className="numbers-dont-lie-desc">
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3rem",
                  alignItems: "flex-end",
                }}
              >
                <h3> 20 Cities</h3>
                <p style={{ textAlign: "end" }}>7000+ Pincodes</p>
              </span>
              <BorderLinearProgress
                variant="determinate"
                sx={{ marginTop: "5px", height: "6px" }}
                value={20}
              />
              <p
                style={{
                  textAlign: "end",
                  fontFamily: "gilroyBold",
                  paddingTop: "2px",
                }}
              >
                We cater inspection in 20 Cities in India
              </p>
            </div>
          </div>
          <div className="price-right-container">
            <img
              style={{ height: "100%", width: "100%", borderRadius: "10px" }}
              src={PriceRightImg}
            ></img>
            <div className="price-image-count-capsule">
              <div className="image-capsule-container">
                <div style={{ display: "flex", gap: "10px" }}>
                  {" "}
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "10px",
                    }}
                    src={inspectImg}
                  ></img>
                  <h6>Used Car Inspection</h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontFamily: "gilroy" }}>
                    Book your used car inspection and guarantee peace of mind
                  </p>
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      sx={{ fontFamily: "gilroyMedium" }}
                      startIcon={<CalendarMonthIcon fontSize="large" />}
                      className="schedule-button-white"
                      variant="outlined"
                    >
                      View Inspection Plans
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="picContainer">
          <h2 className="cardHeader">
            <span>
              Trusted by <span className="count">100,000+ </span>customers
              buying used cars in India
            </span>
          </h2>
          <div className="picCards">
            <strong className="strong">{convert(100000)}+</strong>
            <p>Inspections for used cars</p>
          </div>
          <div className="picCards">
            <strong className="strong1">{convert(20000)}+</strong>
            <p>Happy Warranty Customers in India</p>
          </div>
          <div className="picCards">
            <strong className="strong2">{convert(8000000)}+</strong>
            <p>Kilometers travelled by customers</p>
          </div>
          <div className="picCards">
            <strong className="strong3">{convert(22000)}+</strong>
            <p>Pincodes covered for warranty</p>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Price;
