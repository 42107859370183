import React, { useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Button } from "@mui/material";
import { basicAuth } from "../../Api/crmPortal";
import BuyNowModal from "../popups/buyNowModal";
import CircularProgress from "@mui/material/CircularProgress";

const PaymentForm = ({ userData, packageDetails, isButtondisabled }) => {
  // console.log("isButtondisabled", isButtondisabled);
  // const [openProceedTopay, setOpenProceedToPay] = useState(false);
  const [loaderOpen, setLoader] = useState(false);
  // const handleClose = () => {
  //   // window.open(
  //   //   "https://api.payu.in/public/#/feab6a29ed881b0a680ef98e0971a6ba/paymentoptions",
  //   //   "_blank"
  //   // );
  //   setOpenProceedToPay(false);
  // };
  // const handleApprove = () => {
  //   window.open(
  //     "https://api.payu.in/public/#/feab6a29ed881b0a680ef98e0971a6ba/paymentoptions",
  //     "_blank"
  //   );
  //   setOpenProceedToPay(false);
  // };
  const handleSubmit = async (event) => {
    setLoader(true);
    // setOpenProceedToPay(true);
    try {
      const formData = {
        amount: packageDetails?.amount,
        productinfo: packageDetails?.productinfo,
        phone: packageDetails?.phone,
        firstName: packageDetails?.firstName,
        packageInstanceId: packageDetails?.packageInstanceId,
        couponCode: packageDetails?.couponCode,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CRM_PAY_URL}/paymentgw/initpay`,
        formData,
        {
          auth: {
            username: "prasad",
            password: "prasad",
          },
        }
      );
      if (response?.data) {
        window.location.href = response?.data?.url;
        // setOpenProceedToPay(true);
        // setLoader(false);
      }
      console.log("response", response?.data);
      // window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{ width: "100%", opacity: loaderOpen && "0.5" }}>
      <Button
        variant="contained"
        sx={{
          height: "50px",
          width: "100%",
          backgroundColor: "#003CC5",
          fontFamily: "gilroyMedium",
          fontSize: "18px",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#003CC5",
            boxShadow: "none",
          },
        }}
        onClick={(e) => handleSubmit(e)}
        disabled={!isButtondisabled}
      >
        Proceed to Pay
      </Button>
      {loaderOpen && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* <BuyNowModal
        open={openProceedTopay}
        handleClose={handleClose}
        loaderOpen={loaderOpen}
        handleApprove={handleApprove}
      /> */}
    </div>
  );
};

export default PaymentForm;
