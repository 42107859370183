import React, { useEffect, useState } from "react";
import "./benifitsNew.css";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EastIcon from "@mui/icons-material/East";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const basicChips = [
  "#Engine",
  "#Transmission",
  "#OBD reports",
  "#Mechanical parts",
  "#Electrical parts",
  "#ECM Module",
  "#BCM Module",
  "#TCM Module",
  "#Accident History",
  "#Police verification",
];
const warrantyChips = [
  "#Up to 10 Lakhs",
  "#Engine",
  "#Transmission",
  "#AC Compressor",
  "#Pan India Warranty",
  "#Roadside Assistance",
  "#Loan protection Guarantee",
  "#Buyback Guarantee",
];
const freedomChips = [
  "#Periodic Maintenance",
  "#Showroom Service",
  "#Network Garages",
  "#All cities in India",
  "#24/7 Support",
  "#AC Service",
  "#Health Check ups",
  "#Running Maintenance issues",
];
function Benifits({ handleScrollGrade }) {
  const [isMobile, setIsMobile] = useState(false);
  const [benifitsSelected, setBenifitsSelected] = useState("inspection");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);
  }, []);
  const handleDelete = () => {
    alert("test");
  };
  const handleOpenWhatsApp = () => {
    const phoneNumber = "7338073380";
    const message = "Hi"; // Optional message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the URL in a new tab or window
    window.open(url, "_blank");
  };
  return (
    <section className="benifits-container">
      <div className="container" style={{ marginBottom: "25px" }}>
        <p className="benifits-note">
          Wisedrive{"   "}
          <span style={{ fontFamily: "gilroyBold", fontSize: "24px" }}>
            protects your investment{" "}
          </span>
          before buying used car with its 360° inspection and post purchase{" "}
          <span style={{ fontFamily: "gilroyBold", fontSize: "24px" }}>
            saves your money
          </span>{" "}
          from costly repairs and periodic maintenance at brand showrooms and
          network garages across India.
        </p>
        {/* </div> */}
      </div>
      <div className="container">
        <div
          classname="benifits-wrap"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "",
          }}
        >
          <div className="benifits-left-section">
            <div className="benifits-left-content">
              <h2>
                We’ve covered everything to protect your car and Investment.
              </h2>
              {/* <p>
                {" "}
                Create a holistic benefits program by curating from our
                offerings.
              </p> */}
            </div>

            <div className="btn-insurance-conatiner-left">
              <Button
                onClick={() => setBenifitsSelected("inspection")}
                className={
                  benifitsSelected === "inspection"
                    ? "btn-insurance insurance-active"
                    : "btn-insurance"
                }
                variant="contained"
                endIcon={
                  benifitsSelected === "inspection" && (
                    <ChevronRightIcon sx={{ width: "25px", height: "25px" }} />
                  )
                }
              >
                Used car inspection
              </Button>
              <Button
                onClick={() => setBenifitsSelected("warranty")}
                className={
                  benifitsSelected === "warranty"
                    ? "btn-insurance insurance-active"
                    : "btn-insurance"
                }
                variant="contained"
                endIcon={
                  benifitsSelected === "warranty" && (
                    <ChevronRightIcon sx={{ width: "25px", height: "25px" }} />
                  )
                }
              >
                Extended Warranty
              </Button>
              <Button
                onClick={() => setBenifitsSelected("freedom")}
                className={
                  benifitsSelected === "freedom"
                    ? "btn-insurance insurance-active"
                    : "btn-insurance"
                }
                variant="contained"
                endIcon={
                  benifitsSelected === "freedom" && (
                    <ChevronRightIcon sx={{ width: "25px", height: "25px" }} />
                  )
                }
              >
                Service & Maintainance
              </Button>
            </div>
          </div>
          <div
            className="benifits-right-section"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="btn-insurance-container">
              <Button
                onClick={() => setBenifitsSelected("inspection")}
                className={
                  benifitsSelected === "inspection"
                    ? "btn-insurance-mobile insurance-active"
                    : "btn-insurance-mobile"
                }
                variant="contained"
              >
                Used car Inspection
              </Button>
              <Button
                onClick={() => setBenifitsSelected("warranty")}
                className={
                  benifitsSelected === "warranty"
                    ? "btn-insurance-mobile insurance-active"
                    : "btn-insurance-mobile"
                }
                variant="contained"
              >
                Extended Warranty
              </Button>
              <Button
                onClick={() => setBenifitsSelected("freedom")}
                className={
                  benifitsSelected === "freedom"
                    ? "btn-insurance-mobile insurance-active"
                    : "btn-insurance-mobile"
                }
                variant="contained"
              >
                Service & Maintainance
              </Button>
            </div>

            {/* {isMobile && (
              <Button
                onClick={() => setBenifitsSelected("inspection")}
                className={
                  benifitsSelected === "inspection"
                    ? "btn-insurance insurance-active"
                    : "btn-insurance"
                }
                variant="contained"
                startIcon={
                  <img
                    src={EngineWarrantyIcon}
                    alt="rupee"
                    width="40px"
                    height="40px"
                  />
                }
              >
                Inspection
              </Button>
            )} */}
            {benifitsSelected === "inspection" && (
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      Complete{" "}
                      <span className="card-highlet">Engine Inspection</span>
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Spark plug, Fuel Injectors, ECM(Electronic Control
                      module), Throttle body ...
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span>+36 parts Inspected</span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      Complete{" "}
                      <span className="card-highlet">
                        Transmission Inspection
                      </span>
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      FlyWheel, Shaft, Bearings, Transfer Gears, Gearbox Control
                      Unit (GCU/TCM) ...
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span>+28 parts Inspected</span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      All{" "}
                      <span className="card-highlet">Electrical Parts </span>{" "}
                      Inspection
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Alternator, Starter motor, Ignition Switch, Spark plug,
                      Glow plug, Ignition coil, Fusebox ...
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span>+64 parts Inspected</span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      All{" "}
                      <span className="card-highlet">Mechanical Parts </span>
                      Inspection
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Driveshaft, Differential, Axles, Suspension system,
                      Steering system, Braking system ...
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span>+81 parts Inspected</span>
                  </div>
                </div>
              </div>
            )}
            {/* {isMobile && (
              <Button
                onClick={() => setBenifitsSelected("warranty")}
                className={
                  benifitsSelected === "warranty"
                    ? "btn-insurance insurance-active"
                    : "btn-insurance"
                }
                variant="contained"
                startIcon={
                  <img
                    src={EngineWarrantyIcon}
                    alt="rupee"
                    width="40px"
                    height="40px"
                  />
                }
              >
                Warranty
              </Button>
            )} */}
            {benifitsSelected === "warranty" && (
              <div className="benifits-cards-wrap tab-active-sub ">
                <div className="benifits-card benifits-full ">
                  <div className="benifits-card-content-full">
                    <h6 className="product-item-heading">
                      <span className="card-highlet">Showroom grade</span>{" "}
                      Warranty
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Wisedrive provides Showroom-grade warranty with 95% parts
                      covered in engine and with 98% parts covered in
                      Transmission (automatic and Manual).{" "}
                      <span
                        style={{
                          fontFamily: "gilroyBold",
                        }}
                      >
                        Get warranty up to Rs 10 Lakhs for your car anywhere in
                        India.
                      </span>
                    </p>
                    <p>
                      We partnered with authorised service centres and private
                      service centres for warranty execution.
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <a href="#garde-warranty">
                      <span>Know more &nbsp; →</span>
                    </a>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      <span className="card-highlet">Loan Protection</span>{" "}
                      Program
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Don’t Pay for your loan if your car is in repair with us.
                      We will pay the loan during that period.
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span>Launching Soon ...</span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      <span className="card-highlet">Buyback</span> Guarantee
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      If we cannot repair your car within 60 days, we will buy
                      your car at the invoice price.
                    </p>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span>Launching Soon ...</span>
                  </div>
                </div>
              </div>
            )}
            {/* {isMobile && (
              <Button
                onClick={() => setBenifitsSelected("freedom")}
                className={
                  benifitsSelected === "freedom"
                    ? "btn-insurance insurance-active"
                    : "btn-insurance"
                }
                variant="contained"
                startIcon={
                  <img
                    src={EngineWarrantyIcon}
                    alt="rupee"
                    width="40px"
                    height="40px"
                  />
                }
              >
                Freedom
              </Button>
            )} */}
            {benifitsSelected === "freedom" && (
              <div className="benifits-cards-wrap tab-active-sub ">
                <div className="benifits-card benifits-full ">
                  <div className="benifits-card-content-full">
                    <h6 className="product-item-heading">
                      Periodic Maintenance at{" "}
                      <span className="card-highlet">
                        Authorized service center
                      </span>
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      We partnered with Authorised service centres of all brands
                      to take the best care for your car. All Periodic
                      maintenance services can be availed at our partner service
                      centres at discounted prices.
                    </p>
                    <p>
                      Our{" "}
                      <span
                        style={{
                          fontFamily: "gilroyBold",
                        }}
                      >
                        Service protect program ensures high quality service at
                        authorised service centres{" "}
                      </span>
                      with service history maintained which guarantees a good
                      resale value for your car.
                    </p>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      <span className="card-highlet">Regular maintenance</span>{" "}
                      requests
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Break pads, clutch repairs, wheel balancing,
                      consumables top ups …
                    </p>
                  </div>
                </div>
                <div className="benifits-card">
                  <div className="benifits-card-content">
                    <h6 className="product-item-heading">
                      <span className="card-highlet">Roadside Assistance</span>{" "}
                      anywhere in India
                    </h6>
                  </div>
                  <div className="benifits-card-content-base">
                    <p>
                      Travel FREE with our Roadside assistance plan
                      anywhere in India
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="sticky-contactUs">
            <div className="sticky-contactUs-container">
              <p
                style={{
                  paddingTop: "3px",
                  color: "white",
                  fontFamily: "gilroy",
                }}
              >
                Contact us on{" "}
                <span style={{ fontFamily: "gilroySemiBold" }}>
                  WhatsApp at 73380 73380{" "}
                </span>
                for any queries 24/7. Happy to Assist.
              </p>
              <Button
                variant="text"
                sx={{ color: "white" }}
                startIcon={
                  <WhatsAppIcon
                    sx={{ width: "25px", height: "25px", color: "white" }}
                  />
                }
                endIcon={
                  <EastIcon
                    sx={{ width: "25px", height: "25px", color: "white" }}
                  />
                }
                onClick={() => handleOpenWhatsApp()}
              >
                Open Whatsapp
              </Button>
            </div>
            <div
              onClick={() => handleOpenWhatsApp()}
              className="sticky-contactUs-container-mobile "
            >
              <p
                style={{
                  paddingTop: "3px",
                  color: "white",
                  fontFamily: "gilroy",
                  fontSize: "16px",
                }}
              >
                For queries, send Hi on{" "}
                <span style={{ fontFamily: "gilroySemiBold" }}>WhatsApp</span>{" "}
              </p>
              <EastIcon
                sx={{ width: "25px", height: "25px", color: "white" }}
              />
            </div>
          </div>
        </div>
        {/* <div className="chips-container">
          {benifitsSelected === "inspection" && (
            // <Stack direction="row" spacing={2}>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              {basicChips?.map((val, index) => {
                return (
                  <Chip
                    key={index}
                    className="benifits-chip"
                    label={val}
                    onClick={handleDelete}
                    onDelete={handleDelete}
                    deleteIcon={<DoneIcon sx={{ color: "black !important" }} />}
                    disabled
                  />
                );
              })}
            </Stack>
          )}
          {benifitsSelected === "warranty" && (
            <>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                {warrantyChips?.map((val, index) => {
                  return (
                    <Chip
                      className="benifits-chip"
                      key={index}
                      label={val}
                      onClick={handleDelete}
                      onDelete={handleDelete}
                      deleteIcon={
                        <DoneIcon sx={{ color: "black !important" }} />
                      }
                      disabled
                    />
                  );
                })}
              </Stack>
            </>
          )}
          {benifitsSelected === "freedom" && (
            <>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                {freedomChips?.map((val, index) => {
                  return (
                    <Chip
                      key={index}
                      className="benifits-chip"
                      label={val}
                      onClick={handleDelete}
                      onDelete={handleDelete}
                      deleteIcon={
                        <DoneIcon sx={{ color: "black !important" }} />
                      }
                      disabled
                    />
                  );
                })}
              </Stack>
            </>
          )}
        </div> */}
      </div>
    </section>
  );
}

export default Benifits;
